<template>
  <div class="summary-box">
    <div class="title">{{ $t('clubsSummary.summary.title') }}</div>
    <div class="table">
      <div class="row flex col-center">
        <div class="label flex col-center">{{ $t('clubsSummary.summary.headQuarters.label') }}</div>
        <div class="content flex col-center">
          {{ $t('clubsSummary.summary.headQuarters.content') }}
        </div>
      </div>
      <div class="row flex col-center">
        <div class="label flex col-center">
          {{ $t('clubsSummary.summary.representative.label') }}
        </div>
        <div class="content flex col-center">
          {{ $t('clubsSummary.summary.representative.content') }}
        </div>
      </div>
      <div class="row flex col-center large">
        <div class="label flex col-center">
          {{ $t('clubsSummary.summary.headOffice.label') }}
        </div>
        <div class="content flex col-center">
          {{ $t('clubsSummary.summary.headOffice.content') }}
        </div>
      </div>
      <div class="row flex col-center">
        <div class="label flex col-center">
          {{ $t('clubsSummary.summary.permitApprovalNumber.label') }}
        </div>
        <div class="content flex col-center">
          {{ $t('clubsSummary.summary.permitApprovalNumber.content') }}
        </div>
      </div>
    </div>
    <div class="table">
      <div class="row flex col-center">
        <div class="label flex col-center">
          {{ $t('clubsSummary.summary.managementCompany.label') }}
        </div>
        <div class="content flex col-center">
          {{ $t('clubsSummary.summary.managementCompany.content') }}
        </div>
      </div>
      <div class="row flex col-center">
        <div class="label flex col-center">
          {{ $t('clubsSummary.summary.representative2.label') }}
        </div>
        <div class="content flex col-center">
          {{ $t('clubsSummary.summary.representative2.content') }}
        </div>
      </div>
      <div class="row flex col-center large">
        <div class="label flex col-center">
          {{ $t('clubsSummary.summary.salesOffice.label') }}
        </div>
        <div class="content flex col-center">
          {{ $t('clubsSummary.summary.salesOffice.content') }}
        </div>
      </div>
      <div class="row flex col-center">
        <div class="label flex col-center">
          {{ $t('clubsSummary.summary.permitApprovalNumber2.label') }}
        </div>
        <div class="content flex col-center">
          {{ $t('clubsSummary.summary.permitApprovalNumber2.content') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClubsSummary2',
  data() {
    return {}
  },
  created() {}
}
</script>

<style scoped lang="scss">
.summary-box {
  color: $light-color;
  background-color: $bg-color;
  padding: 200px 330px 180px;
  box-sizing: border-box;
  .title {
    font-size: 56px;
    line-height: 60px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.56);
    margin-bottom: 70px;
  }
  .table {
    &:last-child {
      margin-top: 10px;
    }
    .row {
      margin-top: -1px;
      &:first-child {
        margin-top: 0;
        .label {
          border-top: 1px solid #bbb290;
          border-left: 1px solid #bbb290;
        }
      }
      &:nth-child(2),
      &:nth-child(3) {
        .label {
          border-left: 1px solid #bbb290;
        }
      }
      &:last-child {
        .label {
          border-left: 1px solid #bbb290;
          border-bottom: 1px solid #bbb290;
        }
      }
      .label {
        width: 365px;
        padding: 24px;
        height: 84px;
        gap: 10px;
        border: 1px solid $bg-color;
        box-sizing: border-box;
        background: $primary-color;
        color: $bg-color;
        font-size: 24px;
        font-weight: 600;
      }
      .content {
        width: 895px;
        padding: 24px;
        height: 84px;
        border: 1px solid $primary-color;
        box-sizing: border-box;
        white-space: pre-line;
        font-size: 24px;
      }
    }
    .large {
      .label,
      .content {
        height: 156px;
      }
    }
  }
}
.zh,
.zhf {
  .table {
    font-family: irohamaru !important;
  }
}
</style>
