<template>
  <div class="summary-box">
    <div class="desc-box">
      <div class="title">{{ $t('clubsSummary.info.title') }}</div>
      <div class="desc">
        {{ $t('clubsSummary.info.desc') }}
      </div>
    </div>
    <img :src="imgUrl" />
  </div>
</template>

<script>
export default {
  name: 'ClubsSummary',
  data() {
    return {
      imgUrl: process.env.VUE_APP_FILE_URL + 'summary/1.png'
    }
  },
  created() {}
}
</script>

<style scoped lang="scss">
.summary-box {
  color: $light-color;
  background-color: $bg-color;
  padding: 0 0 100px;
  text-align: center;
  .desc-box {
    padding: 121px 0 40px;
    background: #020202;
    .title {
      font-weight: bold;
      font-size: 80px;
      line-height: 96px;
    }
    .desc {
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: 0.2px;
      margin: 40px 0 0;
      white-space: pre-wrap;
    }
  }

  img {
    width: 100vw;
    height: 768px;
  }
}
</style>
