<template>
  <div class="choose-box">
    <div class="title">{{ $t('clubsSummary.service.title') }}</div>
    <div class="scroll-box">
      <div class="top">
        <vue-seamless-scroll
          :key="key"
          :data="listData"
          :class-option="classOption"
          class="warp"
          ref="scroll"
        >
          <ul class="ul-item">
            <li class="li-item" v-for="(item, index) in listData" :key="index">
              <div class="flex col-center row-between title-box">
                <img :src="item.src" />
                <div class="name">
                  {{ $t('clubsSummary.service.line1.swiper' + (index + 1) + '.title') }}
                </div>
              </div>
              <div class="desc">
                {{ $t('clubsSummary.service.line1.swiper' + (index + 1) + '.desc') }}
              </div>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
      <div class="bottom">
        <vue-seamless-scroll
          :key="key"
          :data="listData2"
          :class-option="classOption2"
          class="warp"
          ref="scroll"
        >
          <ul class="ul-item">
            <li class="li-item" v-for="(item, index) in listData2" :key="index">
              <div class="flex col-center row-between title-box">
                <img :src="item.src" />
                <div class="name">
                  {{ $t('clubsSummary.service.line2.swiper' + (index + 1) + '.title') }}
                </div>
              </div>
              <div class="desc">
                {{ $t('clubsSummary.service.line2.swiper' + (index + 1) + '.desc') }}
              </div>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
    <div class="title title2">{{ $t('clubsSummary.service.title2') }}</div>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'

export default {
  name: 'UseCar',
  components: {
    vueSeamlessScroll
  },
  data() {
    return {
      listData: [
        {
          src: require('@/assets/images/clubsSummary/l1.png')
        },
        {
          src: require('@/assets/images/clubsSummary/l2.png')
        },
        {
          src: require('@/assets/images/clubsSummary/l3.png')
        },
        {
          src: require('@/assets/images/clubsSummary/l4.png')
        },
        {
          src: require('@/assets/images/clubsSummary/l5.png')
        }
      ],
      listData2: [
        {
          src: require('@/assets/images/clubsSummary/b1.png')
        },
        {
          src: require('@/assets/images/clubsSummary/b2.png')
        },
        {
          src: require('@/assets/images/clubsSummary/b3.png')
        },
        {
          src: require('@/assets/images/clubsSummary/b4.png')
        },
        {
          src: require('@/assets/images/clubsSummary/b5.png')
        }
      ],
      classOption: {
        limitMoveNum: 2,
        direction: 2,
        step: 0.2,
        hoverStop: false
      },
      classOption2: {
        limitMoveNum: 2,
        direction: 3,
        step: 0.2,
        hoverStop: false
      },
      key: 0,
      isPlay: true
    }
  },
  watch: {
    '$i18n.locale'() {
      this.key = Math.random()
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.key = Math.random()
    }
  }
}
</script>

<style lang="scss" scoped>
.choose-box {
  background-color: $bg-color;
  padding: 200px 0 200px;
  .title {
    color: rgba(255, 255, 255, 0.56);
    font-size: 56px;
    font-weight: 500;
    line-height: 60px;
    margin-left: 300px;
  }
  .scroll-box {
    .top {
      margin-top: 60px;
      margin-left: 290px;
    }
    .btn {
      margin-bottom: 40px;
      text-align: right;
      padding: 0 40px;
      .iconfont {
        color: $light-color;
        font-size: 36px;
        cursor: pointer;
      }
    }
    .warp {
      width: 360px * 5;
      //height: 260px;
      margin: 0 auto;
      //overflow: hidden;
      ul {
        list-style: none;
        padding: 0;
        margin: 0 auto;
        &.ul-item {
          display: flex;
          .li-item {
            width: 340px;
            height: 436px;
            margin-left: 20px;
            color: #fff;
            padding: 30px;
            font-size: 24px;
            line-height: 28px;
            letter-spacing: 0.2px;
            border-radius: 18px;
            background: #020202;
            img {
              width: 50px;
              height: 50px;
            }
            .name {
              font-weight: bold;
              white-space: pre-line;
              text-align: right;
            }

            .title-box {
              height: 56px;
            }

            .desc {
              margin-top: 15px;
              white-space: pre-line;
            }
          }
        }
      }
    }
  }
  .bottom {
    margin-top: 20px;
    position: relative;
    right: 580px;
  }
  .title2 {
    margin-top: 60px;
    text-align: right;
    padding: 0 330px 0 0;
  }
}
</style>
