<template>
  <GmapMap @click="clickMap" :center="center" :zoom="zoom" class="map">
    <GmapMarker
      :key="index"
      v-for="(m, index) in markers"
      :position="m.position"
      @click="clickMarker(m.position)"
    />
  </GmapMap>
</template>
<script>
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCX3e5pJ_2ixyQLtvI_D9SYUmZgGvkchQc' // 替换为你的Google Maps API 密钥
  }
})
export default {
  name: 'GoogleMaps',
  props: {
    lat: {
      type: Number,
      default: 35.701489
    },
    lng: {
      type: Number,
      default: 139.770241
    }
  },
  data() {
    return {
      //35.710128, 139.897244東京都千代田区外神田3丁目5番5号;東京都江戸川区西篠崎2丁目19番9号35.710128, 139.897244
      center: {}, // 中心位置
      zoom: 16,
      markers: []
    }
  },
  mounted() {
    this.center = { lat: this.lat, lng: this.lng }
    this.markers = [{ position: { lat: this.lat, lng: this.lng } }]
  },
  methods: {
    clickMap(e) {
      // this.hasSetPin = true
      // let longlat = e.latLng.lat() + ',' + e.latLng.lng()
      // this.center_ = {
      //   lat: e.latLng.lat(),
      //   lng: e.latLng.lng()
      // }
      // this.markers = []
      // this.markers.push({ position: this.center_ })
    },
    clickMarker() {
      this.$emit('clickMarker')
    }
  }
}
</script>

<style scoped lang="scss">
.map {
  width: 630px;
  height: 411px;
}
.vue-map-container .vue-map {
  width: 630px;
  height: 411px;
}
</style>
