<template>
  <div class="address-box">
    <div class="title">{{ $t('clubsSummary.address.title') }}</div>
    <div class="row flex">
      <div class="left">
        <div class="name">{{ $t('clubsSummary.address.info1.name') }}</div>
        <div class="address">{{ $t('clubsSummary.address.info1.detail') }}</div>
        <div class="tel">TEL：03-6262-9696</div>
      </div>
      <!--      <img src="https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg" />-->
      <google-maps class="map" :lat="lat" :lng="lng" @clickMarker="clickMarker" />
    </div>
    <div class="row flex">
      <div class="left">
        <div class="name">{{ $t('clubsSummary.address.info2.name') }}</div>
        <div class="address">{{ $t('clubsSummary.address.info2.detail') }}</div>
        <div class="tel">TEL：03-5664-6122</div>
      </div>
      <google-maps class="map" :lat="lat2" :lng="lng2" @clickMarker="clickMarker2" />
      <!--      <img-->
      <!--        class="map"-->
      <!--        src="https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg"-->
      <!--      />-->
    </div>
  </div>
</template>

<script>
import GoogleMaps from '@/components/Moudle/ClubsSummary/GoogleMaps.vue'

export default {
  name: 'ClubsAddress',
  components: { GoogleMaps },
  data() {
    return {
      lat: 35.701489,
      lng: 139.770241,
      lat2: 35.710128,
      lng2: 139.897244
    }
  },
  methods: {
    clickMarker() {
      window.open(
        'https://www.google.com.hk/maps/dir//3-ch%C5%8Dme-5-5-5+Sotokanda,+Chiyoda+City,+Tokyo+101-0021%E6%97%A5%E6%9C%AC/@35.7019214,139.7669641,17z/data=!3m1!4b1!4m9!4m8!1m1!4e2!1m5!1m1!1s0x60188c1e91a15d79:0xbc1aac76a675d2e3!2m2!1d139.769539!2d35.7019171?hl=zh-CN&entry=ttu'
      )
    },
    clickMarker2() {
      window.open(
        'https://www.google.com.hk/maps/dir//2+Chome-19-9+Kita-Shinozaki,+%E6%B1%9F%E6%88%B7%E5%B7%9D%E5%8C%BA%E4%B8%9C%E4%BA%AC%E9%83%BD%E6%97%A5%E6%9C%AC/@35.7177613,139.8560589,13z/data=!3m1!4b1!4m9!4m8!1m1!4e2!1m5!1m1!1s0x601886f5a0a6e763:0xde82659bdea5353d!2m2!1d139.8972584!2d35.7176986?hl=zh-CN&entry=ttu'
      )
    }
  }
}
</script>

<style scoped lang="scss">
.address-box {
  color: $bg-color;
  background-color: $bg-color;
  padding: 200px 330px 127px;
  .title {
    font-size: 56px;
    line-height: 60px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.56);
    margin-bottom: 40px;
  }
  .row {
    &:last-child {
      margin-top: 10px;
    }
    .left {
      width: 630px;
      height: 411px;
      display: flex;
      flex-direction: column;
      place-content: center;
      place-items: center;
      gap: 14px;
      flex-shrink: 0;
      border: 1px solid #161617;
      box-sizing: border-box;
      background: $primary-color;
      .name {
        font-weight: 600;
        font-size: 40px;
        line-height: 59px;
        white-space: pre-line;
        text-align: center;
      }
      .address,
      .tel {
        font-size: 28px;
        line-height: 42px;
        margin-top: 14px;
        white-space: pre-wrap;
      }
    }
    .map {
      width: 630px;
      height: 411px;
      border: 1px solid #ffffff33;
      box-sizing: border-box;
    }
  }
}
.zh,
.zhf {
  .left {
    font-family: irohamaru !important;
  }
}
</style>
