<template>
  <div>
    <clubs-summary id="summary" />
    <clubs-summary2 id="summary2" />
    <clubs-address />
    <use-car id="car" />
  </div>
</template>

<script>
import ClubsSummary from '@/components/Moudle/ClubsSummary/ClubsSummary.vue'
import ClubsSummary2 from '@/components/Moudle/ClubsSummary/ClubsSummary2.vue'
import ClubsAddress from '@/components/Moudle/ClubsSummary/ClubsAddress.vue'
import UseCar from '@/components/Moudle/ClubsSummary/UserCar.vue'

export default {
  components: { UseCar, ClubsAddress, ClubsSummary2, ClubsSummary },
  data() {
    return {}
  },
  mounted() {
    if (this.$route.query.type == 1) {
      this.$nextTick(() => {
        let top = document.getElementById('summary').offsetTop
        window.scrollTo({ top: top, behavior: 'smooth' })
      })
    } else if (this.$route.query.type == 2) {
      this.$nextTick(() => {
        let top = document.getElementById('summary2').offsetTop
        window.scrollTo({ top: top, behavior: 'smooth' })
      })
    } else if (this.$route.query.type == 4) {
      this.$nextTick(() => {
        let top = document.getElementById('car').offsetTop
        window.scrollTo({ top: top, behavior: 'smooth' })
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
